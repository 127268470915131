import { useForm, ValidationError } from "@formspree/react";
import React, { FormEvent } from "react";
import useBoolean from "../hooks/useBoolean";
import Button from "./UI/Button";
import Input from "./UI/Input";
import Switch from "./UI/Switch";

const ContactForm: React.FC = () => {
  const [state, handleSubmit] = useForm("mvoegabp");
  const [isArgee, setAgree] = useBoolean();

  const onSubmit = (event: any) => {
    if (!isArgee) {
      event.preventDefault();
      return;
    };

    handleSubmit(event);
  }

  return (
    <div>
      <form className="text-center" onSubmit={onSubmit}>
        <div className="flex lg:flex-row flex-col gap-6 items-center mb-4 lg:mb-[33px]">
            <Input
                type="text"
                inputProps={{ name: "Your name", required: true }}
                placeholder="Your name"
                label="Your name"
                className="!w-full"
                isRequired
            />
            <ValidationError
                prefix="Your name"
                field="Your name"
                errors={state.errors}
            />
            <Input
                type="email"
                placeholder="Email"
                className="!w-full"
                inputProps={{ name: "Email", required: true }}
                label="Email"
                isRequired
            />
            <ValidationError
                prefix="Email"
                field="Email"
                errors={state.errors}
            />
        </div>
        <div className="flex lg:flex-row flex-col gap-6 items-center mb-4 lg:mb-[33px]">
            <Input
                type="text"
                inputProps={{ name: "Company", required: true }}
                placeholder="Company"
                label="Company"
                className="!w-full"
                isRequired
            />
            <ValidationError
                prefix="Company"
                field="Company"
                errors={state.errors}
            />
            <Input
                type="text"
                placeholder="Country"
                className="!w-full"
                inputProps={{ name: "Country", required: true }}
                label="Country"
                isRequired
            />
            <ValidationError
                prefix="Country"
                field="Country"
                errors={state.errors}
            />
        </div>
        <div className="flex lg:flex-row gap-8 lg:gap-4 flex-col justify-center">
          <Button
            actionType="submit"
            color="red"
            label="Register now"
          />
        </div>
      </form>
      {state.succeeded ? (
        <p className="text-xl text-center mb-4 lg:mt-[26px]">
          Thanks for submitting! We will contact with you soon.
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContactForm;
