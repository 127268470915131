import React from "react";

import RegisterForm from "../../components/RegisterForm";
import DefaultLayout from "../../components/layouts/DefaultLayout";
import SEO from "../../components/layouts/SEO";
import Hero from "../../components/StaticPage/Hero";


const Contact: React.FC = () => {
  return (
    <DefaultLayout>
        <SEO
            title="Workshop On Real Time Analytics | Ensemble"
            url="https://ensembleai.io/real-time-analytics-workshop"
            desc="Book A Free Workshop On Real Time Analytics With ClickHouse Cloud"
            ogImage="https://ensembleai.io/assets/img/home.png"
        />
        <Hero
            title="Real Time Analytics Workshop"
            backgroundColor={["#40b7de", "#1976e0"]}
            description="Understand The Path To Real Time Analytics"
        />


        <div className="mt-16 max-w-[1190px] px-3 text-center mx-auto">
            <p className="text-base">Implementing real-time analytics requires different technical approaches from the moment that data is created till it is acted upon or rendered on a screen.  We need to capture data from applications using streaming techniques or CDC, rapidly ingest it into a real time database, perform transformations and analytics, and surface it through real time frontends and alerting systems.</p>
            <img className="my-6" src="/assets/img/real-time-analytics-timeline.svg" alt="Real time analytics timeline" width="1140" height="380" loading="lazy" />
            <p className="text-base">For businesses who have been using traditional batch based analytics and data warehousing, this can be a fairly significant change in approach and step up in complexity.  The benefits are however worth it when employees are given a real time view of their world, and when the customer experience can be levelled up with real time data.</p>
        </div>

        <div className="mt-16 max-w-[1190px] pt-6 px-3 text-center mx-auto">
            <h2 className="text-[28px] mb-8 lg:text-[31px] font-bold gradient-blue-text ">
                About The <span className="underimage gradient-blue-text ">Workshop</span>
            </h2>
            <p className="text-base mt-4">We offer a free workshop to qualifying organisations to understand how real time analytics can be deployed in your business, and to share our knowledge on building real-time analytics stacks.  Our workshop covers:</p>
            <br/>
        </div>

        <div className="p-6 pt-2 lg:pb-10 gap-[1rem] gap-y-[1.2rem] max-w-[1190px] flex justify-center flex-wrap mx-auto">
            <div 
                className="
                    lg:max-w-[calc(33.3%-0.67rem)] md:max-w-[calc(50%-0.67rem)] lg:min-w-[calc(33.3%-1rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-6 lg:flex-row lg:gap-8 rounded-2xl transition-transform text-center
                "
                style={{background: 'linear-gradient(to right, rgb(134, 118, 232), rgb(89, 86, 196))'}}
            >
                <h3 className="text-[20px] font-bold mb-4">Technical Architecture</h3>
                <p>The technical implications as you move from traditional batch based ETL systems towards a technology stack based on streaming, real time transformations and real time visualisation tools.</p>
            </div>

            <div 
                className="
                    lg:max-w-[calc(33.3%-0.67rem)] md:max-w-[calc(50%-0.67rem)] lg:min-w-[calc(33.3%-1rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-6 lg:flex-row lg:gap-8 rounded-2xl transition-transform text-center
                "
                style={{background: 'linear-gradient(to right, rgb(244, 159, 82), rgb(229, 126, 32))'}}
            >
                <h3 className="text-[20px] font-bold mb-4">Stream Processing</h3>
                <p>How to process data continuously and in real time to understand and respond to the current state of your world.  The various tools and frameworks for workfing with streaming data and stream processing.</p>
            </div>

            <div 
                className="
                    lg:max-w-[calc(33.3%-0.67rem)] md:max-w-[calc(50%-0.67rem)] lg:min-w-[calc(33.3%-1rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-6 lg:flex-row lg:gap-8 rounded-2xl transition-transform text-center
                "
                style={{background: 'linear-gradient(to right, rgb(82, 186, 193), rgb(63, 147, 193))'}}
            >
                <h3 className="text-[20px] font-bold mb-4">Analysing Real Time Data</h3>
                <p>How to analyse real time event and time series oriented data for immediate insights and situation detection.</p>
            </div>

            <div 
                className="
                    lg:max-w-[calc(33.3%-0.67rem)] md:max-w-[calc(50%-0.67rem)] lg:min-w-[calc(33.3%-1rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-6 lg:flex-row lg:gap-8 rounded-2xl transition-transform text-center
                "
                style={{background: 'linear-gradient(to right, rgb(223, 106, 154), rgb(230, 77, 105))'}}
            >
                <h3 className="text-[20px] font-bold mb-4">AI and Machine Learning</h3>
                <p>How AI and Machine Learning benefit from real-time data, and how real time feature stores and machine learning pipelines can be realised.</p>
            </div>

            <div 
                className="
                    lg:max-w-[calc(33.3%-0.67rem)] md:max-w-[calc(50%-0.67rem)] lg:min-w-[calc(33.3%-1rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-6 lg:flex-row lg:gap-8 rounded-2xl transition-transform text-center
                "
                style={{background: 'linear-gradient(to right, rgb(212, 121, 204), rgb(194, 91, 210))'}}
            >
                <h3 className="text-[20px] font-bold mb-4">ClickHouse Cloud</h3>
                <p>We believe that ClickHouse Cloud is <a href="why-clickhouse-cloud" className="underline">uniquely differentiated</a> as a platform for real-time analytics.  We will introduce and demonstrate this technology to show the role it can play in your modern data stack.</p>
            </div>

            <div 
                className="
                    lg:max-w-[calc(33.3%-0.67rem)] md:max-w-[calc(50%-0.67rem)] lg:min-w-[calc(33.3%-1rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-6 lg:flex-row lg:gap-8 rounded-2xl transition-transform text-center
                "
                style={{background: 'linear-gradient(to right, rgb(122, 216, 155), rgb(75, 185, 144))'}}
            >
                <h3 className="text-[20px] font-bold mb-4">Business Value</h3>
                <p>We will discuss the business value for implementing or migrating to real time and streaming data stacks.</p>
            </div>
        </div>

        <div className="contact-form-block pb-20 pb-[8rem] !pt-[5rem] md:!pt-[5rem]">
            <div className="max-w-[744px] mx-auto px-6 lg:px-10">
                <h2 className="text-[28px] mb-8 lg:text-[31px] font-bold gradient-blue-text text-center">
                    Let's Start <span className="underimage gradient-blue-text ">The Conversation</span>
                </h2>
                <RegisterForm />
            </div>
        </div>
    </DefaultLayout>
  );
};

export default Contact;
